import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import Link from "../common/Link"

const StyledMenu = styled.div`
  padding: 2rem;
  background: ${({ theme }) => theme.colors["blue-800"]};
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 320px;
  bottom: 0;
  transform: translateX(110%);
  transition: all 0.3s;
  box-shadow: 0 2px 10px 0 ${({ theme }) => theme.colors["blue-900"]};
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${props => props.menuOpen && `transform: translateX(0);`};

  a {
    padding: 2rem 0;
    background: 0;
    border: 0;
    color: ${({ theme }) => theme.colors["gray-300"]};
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: 400;
  }

  button {
    margin: 2rem 0;
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.fontFamily};
    border: 0;
    background: ${({ theme }) => theme.colors["blue-900"]};
    color: ${({ theme }) => theme.colors["gray-300"]};
    border-radius: 8px;
    cursor: pointer;
    padding: 1rem;
    font-weight: 500;
    display: inline-block;
  }

  a:hover {
    text-decoration: underline;
  }

  a.--is-active {
    color: ${({ theme }) => theme.colors["gray-100"]};
    font-weight: 500;
  }
`

const MobileMenu = ({ menuOpen, closeMenu }) => {
  return (
    <StyledMenu menuOpen={menuOpen}>
      <StaticQuery
        query={graphql`
          query MobileNavQuery {
            prismicNav(uid: { eq: "site-nav" }) {
              data {
                items {
                  label {
                    text
                  }
                  link {
                    url
                    target
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const nav = data.prismicNav.data
          return nav.items.map(({ label, link }) => (
            <Link
              to={link.url}
              key={label.text}
              target={link.target}
              activeClassName="--is-active"
              onClick={closeMenu}
            >
              {label.text}
            </Link>
          ))
        }}
      />
      <button onClick={closeMenu} type="button">
        X Close
      </button>
    </StyledMenu>
  )
}

MobileMenu.defaultProps = {
  menuOpen: false,
}

MobileMenu.propTypes = {
  menuOpen: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
}

export default MobileMenu
