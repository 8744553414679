import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "./Container"

const PageHeader = ({
  className,
  heading,
  introduction,
  image,
  imageAlt,
  published,
  updated,
}) => {
  return (
    <div className={className}>
      <Container>
        <h1>{heading}</h1>
        <div dangerouslySetInnerHTML={{ __html: introduction }} />
        {published ||
          (updated && (
            <p className="meta">{updated && `Last updated: ${updated}`}</p>
          ))}
        {image && (
          <img className="header-image" src={image} alt={imageAlt || ""} />
        )}
      </Container>
    </div>
  )
}

PageHeader.propTypes = {
  className: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  published: PropTypes.string,
  updated: PropTypes.string,
}

PageHeader.defaultProps = {
  image: undefined,
  imageAlt: undefined,
  published: undefined,
  updated: undefined,
}

export default styled(PageHeader)`
  padding: 3rem 2rem;
  background-color: ${({ theme }) => theme.colors["gray-200"]};

  @media ${({ theme }) => theme.device.mobileL} {
    background-color: ${({ theme }) => theme.colors["gray-100"]};
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 5rem 2rem;
  }

  h1 {
    font-size: 3rem;
    font-weight: normal;
    text-align: center;
    line-height: 0.9;

    @media ${({ theme }) => theme.device.mobileM} {
      font-size: 3.6rem;
    }

    @media ${({ theme }) => theme.device.mobileL} {
      font-size: 4rem;
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 5.5rem;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: 6.5rem;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 1.2;
    text-align: center;
    margin: 2rem auto;
    color: ${({ theme }) => theme.colors["gray-700"]};

    @media ${({ theme }) => theme.device.mobileM} {
      font-size: 2.2rem;
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 3rem;
      max-width: 700px;
    }
  }

  .header-image {
    max-width: 100%;
    display: block;

    @media ${({ theme }) => theme.device.mobileL} {
      margin: 2rem auto 0;
      max-height: 400px;
    }
  }

  .meta {
    font-size: 1.4rem;
    text-transform: uppercase;
  }
`
