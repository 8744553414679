/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
// import { useStaticQuery, graphql } from 'gatsby';

import theme from "../../lib/theme"
import GlobalStyles from "../../lib/GlobalStyles"

import SiteFooter from "../content/SiteFooter"
import SiteHeader from "../content/SiteHeader"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         url
  //       }
  //     }
  //   }
  // `);

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <SiteHeader />
        <main>{children}</main>
        <SiteFooter />
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
