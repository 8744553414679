import React from "react"

const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      className="trustpilot-widget" // Renamed this to className.
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="58e12fa10000ff00059fb2da"
      data-style-height="150px"
      data-style-width="250px"
      data-theme="dark"
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
    >
      <a
        href="https://www.trustpilot.com/review/rateswitch.uk"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  )
}
export default TrustBox
