export const colors = {
  "blue-900": "#0F1D24",
  "blue-800": "#0C2C3B",
  "blue-700": "#133B4D",
  "blue-600": "#224B5E",
  "blue-500": "#385B6B",
  "blue-400": "#537F96",
  "blue-300": "#519FC4",
  "blue-200": "#73C0E6",
  "blue-100": "#9CD5F0",
  "orange-900": "#381407",
  "orange-800": "#8A3415",
  "orange-700": "#EA5925",
  "orange-600": "#EE8834",
  "orange-500": "#F7A823",
  "orange-400": "#FFC057",
  "orange-300": "#FFCF7D",
  "orange-200": "#FFE5BA",
  "orange-100": "#FFF7EB",
  "gray-900": "#121414",
  "gray-800": "#343E42",
  "gray-700": "#5D6E75",
  "gray-600": "#8A9DA6",
  "gray-500": "#BACAD1",
  "gray-400": "#D3E0E6",
  "gray-300": "#E1EBF0",
  "gray-200": "#F5F8FA",
  "gray-100": "#FAFCFC",
  "green-900": "#07381A",
  "green-800": "#158A42",
  "green-700": "#00B545",
  "green-600": "#04CF52",
  "green-500": "#21E86E",
  "green-400": "#53F591",
  "green-300": "#7DFFAF",
  "green-200": "#BAFFD5",
  "green-100": "#EBFFF2",
  "red-900": "#380B07",
  "red-800": "#8A1521",
  "red-700": "#B50C00",
  "red-600": "#D91507",
  "red-500": "#F73223",
  "red-400": "#FF6257",
  "red-300": "#FF867D",
  "red-200": "#FFBFBA",
  "red-100": "#FFECEB",
  white: "#FFFFFF",
}

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}

export default {
  colors,
  device,
  success: colors["green-500"],
  danger: colors["red-500"],

  bodyBg: colors["gray-100"],
  overlayColor: "rgba(15, 29, 36, 0.8)", // gray-900 with 80% alpha

  baseSize: "10px",
  maxWidth: "960px",
  maxWidthWide: "1280px",
  boxShadow: "0 12px 24px 0 rgba (0, 0, 0, 0.8)",

  // Type
  fontFamily:
    '"hk_groteskmedium", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  headingFontFamily:
    '"hk_groteskbold", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  headingColor: colors["blue-600"],
  textColor: colors["gray-800"],
  linkColor: colors["blue-600"],

  inputPlaceholder: colors["gray-500"],
  inputBorder: colors["gray-600"],
  inputColor: colors["gray-800"],
  inputLabelColor: colors["gray-800"],
}
