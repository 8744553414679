import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Button from "../common/Button"
import CTAButton from "../common/CTAButton"

const CTAMain = ({ className, heading, text, buttonLabel, subText }) => {
  return (
    <div className={className}>
      <div className="inner">
        <div className="content">
          <h2>{heading}</h2>
          <p>{text}</p>
          <CTAButton label={buttonLabel} text={subText} />
        </div>
      </div>
    </div>
  )
}

CTAMain.propTypes = {
  className: PropTypes.string.isRequired,
  heading: PropTypes.string,
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  subText: PropTypes.string,
}

CTAMain.defaultProps = {
  heading: "Accelerate to mortgage freedom",
  text: "Get started in under five minutes",
  buttonLabel: "Sign up free",
  subText: "It's fast and simple",
}

export default styled(CTAMain)`
  background-color: ${({ theme }) => theme.colors["gray-100"]};
  padding: 2rem 2rem 4rem;
  margin-top: 1rem;

  @media ${({ theme }) => theme.device.mobileM} {
    padding: 2rem 2.5rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 2rem 2rem;
  }

  h2 {
    font-size: 4rem;
    font-weight: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors["blue-800"]};
    margin-bottom: 1.5rem;

    @media ${({ theme }) => theme.device.mobileL} {
      font-size: 4.2rem;
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 5.5rem;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: 6rem;
      max-width: 500px;
    }
  }

  .inner {
    @media ${({ theme }) => theme.device.tablet} {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      max-width: ${({ theme }) => theme.maxWidth};
      margin: 0 auto;
    }
  }

  /* .ul-wrapper {
    display: block;
    margin: 4rem 0;
  }

  ul {
    padding: 0;
    margin: 0 auto 2rem;
    list-style: none;
  } */

  p {
    text-align: center;
  }

  ${CTAButton} {
    width: 100%;

    @media ${({ theme }) => theme.device.tablet} {
      margin: 3rem 0;
    }

    ${Button} {
      display: block;
      width: 100%;

      @media ${({ theme }) => theme.device.tablet} {
        max-width: 500px;
      }
    }
  }
`
