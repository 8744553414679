import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import Link from "../common/Link"
import Container from "../common/Container"
import TrustBox from "./TrustBox"

import appStore from "../../images/app_store_white.svg"

const SiteFooter = ({ className }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteFooterQuery {
          prismicSiteFooter {
            data {
              left_column_heading {
                text
              }
              left_column_links {
                label {
                  text
                }
                link {
                  url
                  target
                }
              }
              center_column_heading {
                text
              }
              center_column_links {
                label {
                  text
                }
                link {
                  url
                  target
                }
              }
              right_column_heading {
                text
              }
              right_column_links {
                label {
                  text
                }
                link {
                  url
                  target
                }
              }
              legal_copy {
                html
              }
            }
          }
        }
      `}
      render={data => {
        const footer = data.prismicSiteFooter.data
        return (
          <div className={className}>
            <Container wide>
              <div className="columns">
                <div className="column">
                  <h5>{footer.left_column_heading.text}</h5>
                  <ul>
                    {footer.left_column_links.map(({ link, label }) => (
                      <li key={label.text}>
                        <Link to={link.url} target={link.target}>
                          {label.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="column">
                  <h5>{footer.center_column_heading.text}</h5>
                  <ul>
                    {footer.center_column_links.map(({ link, label }) => (
                      <li key={label.text}>
                        <Link to={link.url} target={link.target}>
                          {label.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="column">
                  <h5>{footer.right_column_heading.text}</h5>
                  <ul>
                    {footer.right_column_links.map(({ link, label }) => (
                      <li key={label.text}>
                        <Link to={link.url} target={link.target}>
                          {label.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="footer-logo-links">
                <TrustBox />
                <a
                  href="https://apps.apple.com/gb/app/accelerate-my-mortgage/id1545659732"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appStore}
                    className="app-store-logo"
                    alt="Download on the App Store"
                  />
                </a>
              </div>
              <footer
                dangerouslySetInnerHTML={{ __html: footer.legal_copy.html }}
              />
            </Container>
          </div>
        )
      }}
    />
  )
}

SiteFooter.propTypes = {
  className: PropTypes.string.isRequired,
}

export default styled(SiteFooter)`
  background: ${({ theme }) => theme.colors["blue-700"]};
  color: ${({ theme }) => theme.colors.white};
  padding: 4rem 2rem 2rem;

  h5 {
    font-family: ${({ theme }) => theme.subHeadingFontFamily};
  }

  @media ${({ theme }) => theme.device.tablet} {
    .columns {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .column {
      width: 300px;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.white};
  }

  .logo {
    height: 1.5rem;
  }

  h5 {
    color: ${({ theme }) => theme.colors["blue-100"]};
    font-weight: normal;
    text-transform: none;
  }

  .column {
    margin-bottom: 8rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-size: 2rem;
      margin: 2rem 0;
      a {
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }

  footer {
    padding: 1rem 0;

    p {
      font-size: 1.4rem;
      line-height: 1.2;
      color: ${({ theme }) => theme.colors["gray-400"]};
      max-width: 100%;
    }

    a {
      color: ${({ theme }) => theme.colors["blue-400"]};
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .footer-logo-links {
    .app-store-logo {
      width: 200px;
    }

    @media ${({ theme }) => theme.device.tablet} {
      display: flex;
      align-items: center;

      .app-store-logo {
        margin-left: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
`
