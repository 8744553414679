import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import Cookies from "js-cookie"

import Button from "../common/Button"
import Container from "../common/Container"
import Link from "../common/Link"

import MobileMenu from "./MobileMenu"

import logo from "../../images/amm_logo.svg"

class SiteHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = { menuOpen: false }
  }

  componentDidMount() {
    // Set Referrer Cookies
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const urlReferrer = urlParams.get("referrer")
    if (urlReferrer) {
      const referrerCookie = Cookies.get("amm_partner_referrer")
      if (!referrerCookie)
        Cookies.set("amm_partner_referrer", urlReferrer, {
          expires: 30,
          domain: "acceleratemymortgage.com",
        })
    }
  }

  render() {
    const { className } = this.props
    const { menuOpen } = this.state
    return (
      <div className={className}>
        <Container wide>
          <MobileMenu
            menuOpen={menuOpen}
            closeMenu={() => this.setState({ menuOpen: false })}
          />
          <Link to="/">
            {" "}
            <img className="logo" src={logo} alt="Accelerate My Mortgage" />
          </Link>
          <div className="nav">
            <ul className="desktop-nav-links">
              <StaticQuery
                query={graphql`
                  query SiteNavQuery {
                    prismicNav(uid: { eq: "site-nav" }) {
                      data {
                        items {
                          label {
                            text
                          }
                          link {
                            url
                            target
                          }
                        }
                      }
                    }
                  }
                `}
                render={data => {
                  const nav = data.prismicNav.data
                  return nav.items.map(({ label, link }) => (
                    <li key={label.text}>
                      <Link
                        to={link.url}
                        target={link.target}
                        activeClassName="--active"
                      >
                        {label.text}
                      </Link>
                    </li>
                  ))
                }}
              />
            </ul>
            <Button
              label="Menu"
              className="menu-toggle"
              mode="outline"
              size="s"
              onClick={() => this.setState({ menuOpen: !menuOpen })}
            />
            <Button
              label="Sign up"
              mode="cta"
              size="s"
              linkTo="https://app.acceleratemymortgage.com/signup"
            />
          </div>
        </Container>
      </div>
    )
  }
}

SiteHeader.propTypes = {
  className: PropTypes.string.isRequired,
}

export default styled(SiteHeader)`
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${({ theme }) => theme.colors.white};

  ${Container} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1rem;

    @media ${({ theme }) => theme.device.tablet} {
      padding: 1.5rem;
    }

    @media ${({ theme }) => theme.device.laptop} {
      padding: 2rem;
    }
  }

  .logo {
    height: 3rem;

    @media ${({ theme }) => theme.device.mobileM} {
      height: 3.5rem;
    }

    @media ${({ theme }) => theme.device.tablet} {
      height: 4.5rem;
    }

    @media ${({ theme }) => theme.device.laptop} {
      height: 5rem;
    }
  }

  .nav {
    display: flex;
  }

  .menu-toggle {
    margin-right: 1rem;

    @media ${({ theme }) => theme.device.tablet} {
      display: none;
    }
  }

  .desktop-nav-links {
    display: none;

    @media ${({ theme }) => theme.device.tablet} {
      display: flex;
      list-style: none;
      margin: 0 4rem 0 0;
      padding: 0;
      justify-content: flex-end;
      align-items: center;
    }

    @media ${({ theme }) => theme.device.laptop} {
      margin: 0 6rem 0 0;
    }

    li {
      margin: 0 2rem;
      font-size: 1.6rem;

      @media ${({ theme }) => theme.device.laptop} {
        margin: 0 3.5rem;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors["gray-600"]};

        &.--active {
          color: ${({ theme }) => theme.colors["blue-800"]};
          padding-bottom: 0.5rem;
          margin-bottom: -0.5rem;
          border-bottom: 2px solid ${({ theme }) => theme.colors["orange-500"]};
        }
      }
    }
  }
`
