import { createGlobalStyle } from "styled-components"
import theme, { colors, device } from "./theme"

// Fonts
import hkgMedEOT from "./fonts/hkgrotesk-medium.eot"
import hkgMedWOFF from "./fonts/hkgrotesk-medium.woff"
import hkgMedWOFF2 from "./fonts/hkgrotesk-medium.woff2"

import hkgItalicEOT from "./fonts/hkgrotesk-italic.eot"
import hkgItalicWOFF from "./fonts/hkgrotesk-italic.woff"
import hkgItalicWOFF2 from "./fonts/hkgrotesk-italic.woff2"

import hkgSemEOT from "./fonts/hkgrotesk-semibold.eot"
import hkgSemWOFF from "./fonts/hkgrotesk-semibold.woff"
import hkgSemWOFF2 from "./fonts/hkgrotesk-semibold.woff2"

import hkgBolEOT from "./fonts/hkgrotesk-bold.eot"
import hkgBolWOFF from "./fonts/hkgrotesk-bold.woff"
import hkgBolWOFF2 from "./fonts/hkgrotesk-bold.woff2"

import "sanitize.css"

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'hk_groteskmedium';
    src: url(${hkgMedEOT});
    src: url('${hkgMedEOT}?#iefix') format('embedded-opentype'),
         url(${hkgMedWOFF2}) format('woff2'),
         url(${hkgMedWOFF}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'hk_groteskitalic';
    src: url(${hkgItalicEOT});
    src: url('${hkgItalicEOT}?#iefix') format('embedded-opentype'),
         url(${hkgItalicWOFF2}) format('woff2'),
         url(${hkgItalicWOFF}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'hk_grotesksemibold';
    src: url(${hkgSemEOT});
    src: url('${hkgSemEOT}?#iefix') format('embedded-opentype'),
         url(${hkgSemWOFF2}) format('woff2'),
         url(${hkgSemWOFF}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'hk_groteskbold';
    src: url(${hkgBolEOT});
    src: url('${hkgBolEOT}?#iefix') format('embedded-opentype'),
         url(${hkgBolWOFF2}) format('woff2'),
         url(${hkgBolWOFF}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: ${theme.baseSize};
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  *:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  body {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 1;
    font-family: ${theme.fontFamily};
    color: ${theme.textColor};
    background: ${theme.bodyBg};
    height: 100%;
    min-height: 100vh;
  }

  a {
    text-decoration: none;
    color: ${theme.linkColor};
    :hover {
      text-decoration: underline;
    }
  }

  h1, h2, h3, h4, h5 {
    color: ${theme.headingColor};
    font-family: ${theme.headingFontFamily};
    font-weight: 700;
  }

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 3rem;

    @media ${device.mobileM} {
      font-size: 3.2rem;
    }

    @media ${device.tablet} {
      font-size: 4rem;
    }
  }

  h3 {
    font-size: 2rem;

    @media ${device.mobileM} {
      font-size: 2.4rem;
    }

    @media ${device.tablet} {
      font-size: 3rem;
    }
  }

  h4 {
    font-size: 1.8rem;

    @media ${device.mobileM} {
      font-size: 2rem;
    }

    @media ${device.tablet} {
      font-size: 2.4rem;
    }
  }

  h5 {
    font-size: 1.6rem;
    text-transform: uppercase;

    @media ${device.mobileM} {
      font-size: 1.8rem;
    }

    @media ${device.tablet} {
      font-size: 2rem;
    }
  }

  h6 {
    font-size: 1.6rem;
    text-transform: uppercase;

    @media ${device.tablet} {
      font-size: 1.8rem;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.8;

    a {
      text-decoration: underline;
    }

    strong {
      font-family: ${theme.headingFontFamily};
      font-weight: 700;
    }

    @media ${device.tablet} {
      font-size: 2rem;
    }
  }

  .sub {
    color: ${colors["gray-700"]};
    font-size: 1.8rem;
  }
`

export default GlobalStyles
