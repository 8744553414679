/* eslint-disable react/button-has-type */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Link from "./Link"

const Button = ({
  label,
  className,
  type,
  disabled,
  loading,
  loadingLabel,
  onClick,
  mode,
  size,
  block,
  linkTo,
  ...props
}) =>
  linkTo ? (
    <Link
      mode={mode}
      className={className}
      size={size}
      onClick={onClick}
      disabled={disabled || loading}
      to={linkTo}
    >
      {(loading && loadingLabel) || label}
    </Link>
  ) : (
    <button
      mode={mode}
      className={className}
      type={type}
      size={size}
      disabled={disabled || loading}
      onClick={onClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {(loading && loadingLabel) || label}
    </button>
  )

Button.defaultProps = {
  label: undefined,
  loadingLabel: undefined,
  mode: "default",
  type: "button",
  size: "m",
  disabled: false,
  loading: false,
  block: false,
  onClick: undefined,
  linkTo: undefined,
}

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string.isRequired,
  loadingLabel: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
}

export default styled(Button)`
  border-radius: 0.5rem;

  display: ${({ block }) => (block ? "block" : "inline-block")};
  width: ${({ block }) => (block ? "100%" : "auto")};

  padding: ${({ mode, size }) => {
    if (mode === "link") return "0 1rem"
    if (size === "s") return "1rem"
    return "2rem"
  }};

  font-size: ${({ size }) => {
    if (size === "s") return "1.4rem"
    return "1.6rem"
  }};

  @media ${({ theme }) => theme.device.mobileL} {
    font-size: ${({ size }) => {
      if (size === "s") return "1.6rem"
      return "2rem"
    }};
  }

  border: 1px solid ${({ theme }) => theme.colors["blue-500"]};
  font-family: ${({ theme }) => theme.fontFamily};
  text-decoration: ${({ mode }) =>
    mode === "link" ? "underline" : "none"} !important;
  line-height: 1;
  text-align: center;

  cursor: pointer;

  svg {
    margin-right: 1rem;
  }

  background-color: ${({ theme, mode }) => {
    if (mode === "danger") return theme.colors["red-100"]
    if (mode === "cta") return theme.colors["orange-700"]
    if (mode === "outline") return theme.colors.white
    if (mode === "link") return "transparent !important"
    return theme.colors["gray-400"]
  }};

  color: ${({ theme, mode }) => {
    if (mode === "danger") return theme.danger
    if (mode === "cta") return theme.colors.white
    if (mode === "outline") return theme.colors["blue-500"]
    return theme.linkColor
  }};

  border-color: ${({ theme, mode }) => {
    if (mode === "danger") return theme.danger
    if (mode === "cta") return theme.colors["orange-700"]
    if (mode === "outline") return theme.colors["blue-500"]
    if (mode === "link") return "transparent !important"
    return theme.colors["gray-400"]
  }};

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      opacity: 0.3;
    }
  }

  &:hover {
    background: ${({ theme, mode }) => {
      if (mode === "danger") return theme.danger
      if (mode === "cta") return theme.colors["blue-700"]
      if (mode === "outline") return theme.colors["gray-200"]
      if (mode === "link") return "transparent"
      return theme.colors["gray-500"]
    }};

    border-color: ${({ theme, mode }) => {
      if (mode === "danger") return theme.danger
      if (mode === "cta") return theme.colors["blue-700"]
      if (mode === "outline") return theme.colors["blue-500"]
      if (mode === "link") return "transparent"
      return theme.colors["gray-500"]
    }};

    color: ${({ theme, mode }) => {
      if (mode === "danger") return theme.colors.white
      if (mode === "cta") return theme.colors.white
      if (mode === "outline") return theme.colors["blue-500"]
      if (mode === "link") return theme.colors["blue-400"]
      return theme.linkColor
    }};
  }
`
