import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "./Button"

const CTAButton = ({ className, label, text }) => {
  return (
    <div className={className}>
      <Button
        label={label}
        mode="cta"
        size="l"
        linkTo="https://app.acceleratemymortgage.com/signup"
      />
      <span>{text}</span>
    </div>
  )
}

CTAButton.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string,
  text: PropTypes.string,
}

CTAButton.defaultProps = {
  label: "Sign up for free",
  text: "Free, fast, and simple",
}

export default styled(CTAButton)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  ${Button} {
    max-width: 400px;
  }

  span {
    display: block;
    margin: 1rem auto;
    font-size: 1.6rem;
    text-align: center;
    color: ${({ theme }) => theme.colors["gray-500"]};

    @media ${({ theme }) => theme.device.mobileL} {
      font-size: 1.8rem;
    }
  }
`
